const DEFAULT_KEY = '63616a98-4a55-4c92-8b42-99c6074e3079'

//LOCAL
// const BASE_URL = "http://192.168.10.40:5014/";

//DEMO
// const BASE_URL = "http://162.19.146.135:1528/"


//LIVE
const BASE_URL = "https://admin.woownature.com/node_api/";
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
